import ACTION_TYPES from "../actions/commonActionTypes";

const initialState = {
  appBarComponent: null,
  isProgressBarLoading: false,
  showLogoHideComponent: false,
  backButton: {},
  backButtonForDesktop: false,
};

const appBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.APPBAR_COMPONENT:
      state = {
        ...state,
        appBarComponent: action.payload,
      };
      break;
    case ACTION_TYPES.APPBAR_SET_SHOW_LOGO_HIDE_COMPONENT:
      state = {
        ...state,
        showLogoHideComponent: action.payload,
      };
      break;
    case ACTION_TYPES.APPBAR_SET_BACK_BUTTON:
      state = {
        ...state,
        backButton: action.payload,
      };
      break;
    case ACTION_TYPES.APPBAR_SET_DESKTOP_BACK_BUTTON:
      state = {
        ...state,
        backButtonForDesktop: action.payload,
      };
      break;
    case ACTION_TYPES.APPBAR_SET_IS_PROGRESS_BAR_LOADING:
      state = {
        ...state,
        isProgressBarLoading: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default appBarReducer;
