import ACTION_TYPES from "../actions/commonActionTypes";

const initialState = {
  anchorEl: null,
  appliedSortIndex: "",
  sortBy: "",
  sortOrder: "",
  sortOptions: [],
};

const sortReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SHOW_SORT_MENU:
      state = {
        ...state,
        anchorEl: action.payload,
      };
      break;
    case ACTION_TYPES.UPDATE_SORTORDER:
      state = {
        ...state,
        sortOrder: action.payload,
      };
      break;
    case ACTION_TYPES.UPDATE_SORTDETAILS:
      state = {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
        appliedSortIndex: action.payload.appliedSortIndex,
      };
      break;
    case ACTION_TYPES.SORT_SET_OPTIONS:
      state = {
        ...state,
        sortOptions: [...action.payload],
      };
      break;
    case ACTION_TYPES.SORT_CLEAR_STATE:
      state = {
        ...state,
        anchorEl: initialState.anchorEl,
        appliedSortIndex: initialState.appliedSortIndex,
        sortBy: initialState.sortBy,
        sortOrder: initialState.sortOrder,
        sortOptions: initialState.sortOptions,
      };
      break;
    case ACTION_TYPES.RESET_SORT_BY_AND_ORDER:
      state = {
        ...state,
        appliedSortIndex: initialState.appliedSortIndex,
        sortBy: initialState.sortBy,
        sortOrder: initialState.sortOrder,
      };
      break;
  }
  return state;
};

export default sortReducer;
