import ACTION_TYPES from "../actions/commonActionTypes";

const initialState = {
  alertMessages: new Map(),
};

const alertMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.ALERTS_SET_LIST:
      state = {
        ...state,
        alertMessages: action.payload,
      };
      break;
    case ACTION_TYPES.ALERTS_CLEAR_LIST:
      state = {
        ...state,
        alertMessages: new Map(),
      };
      break;
  }
  return state;
};

export default alertMessageReducer;
