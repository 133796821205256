import ACTION_TYPES from "../actions/commonActionTypes";

const initialState = {
  list: [],
  selectedItem: null,
  selectedSubItem: null,
  expanededItem: -1,
  isNavBarOpen: false,
  showNavBar: false,

  //to hide show navbar
  isHidden: false,
};

const navBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.NAVBAR_GET_LIST:
      state = {
        ...state,
        list: action.payload,
      };
      break;
    case ACTION_TYPES.NAVBAR_SET_EXPANDED_ITEM:
      state = {
        ...state,
        expanededItem: action.payload,
      };
      break;
    case ACTION_TYPES.NAVBAR_TOGGLE_STATE:
      state = {
        ...state,
        isNavBarOpen: !state.isNavBarOpen,
      };
      break;
    case ACTION_TYPES.NAVBAR_SHOW:
      state = {
        ...state,
        showNavBar: action.payload,
      };
      break;
    case ACTION_TYPES.HIDE_SHOW_APPBAR_NAVBAR:
      state = {
        ...state,
        isHidden: action.payload,
      };
  }
  return state;
};

export default navBarReducer;
