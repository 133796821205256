import ACTION_TYPES from "../actions/commonActionTypes";

const initialState = {
  component: null,
  actions: [],
  extraActions: [],
  isOpen: false,
  title: "",
};

const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.DRAWER_SET_STATE:
      state = {
        ...state,
        isOpen: action.payload,
      };
      break;
    case ACTION_TYPES.DRAWER_SET_COMPONENT:
      state = {
        ...state,
        component: action.payload,
      };
      break;
    case ACTION_TYPES.DRAWER_RESET:
      state = { ...initialState };
      break;
    case ACTION_TYPES.DRAWER_SET_TITLE:
      state = {
        ...state,
        title: action.payload,
      };
      break;
    case ACTION_TYPES.DRAWER_SET_ACTIONS:
      state = {
        ...state,
        actions: [...action.payload],
      };
      break;
    case ACTION_TYPES.DRAWER_SET_EXTRA_ACTIONS:
      state = {
        ...state,
        extraActions: [...action.payload],
      };
      break;
  }
  return state;
};

export default drawerReducer;
