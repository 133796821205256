import ACTION_TYPES from "../actions/commonActionTypes";
import { DrawerItem } from "../../utils/multiDrawerUtil";

const initialState = {
  drawers: [new DrawerItem()],
  refreshDrawer: false,
};

const multiDrawerReducer = (state = initialState, action) => {
  const tempDrawers = [...state.drawers];
  switch (action.type) {
    case ACTION_TYPES.SET_REFRESH_MULTI_DRAWER:
      state = {
        ...state,
        refreshDrawer: action.payload,
      };
      break;
    case ACTION_TYPES.MULTI_DRAWER_PUSH:
      tempDrawers.pop();
      tempDrawers.push(action.payload);
      tempDrawers.push(new DrawerItem());
      state = {
        ...state,
        drawers: tempDrawers,
      };
      break;
    case ACTION_TYPES.MULTI_DRAWER_POP:
      tempDrawers.pop();
      tempDrawers.pop();
      tempDrawers.push(new DrawerItem());
      state = {
        ...state,
        drawers: tempDrawers,
        refreshDrawer:
          tempDrawers.length === 1
            ? initialState.refreshDrawer
            : state.refreshDrawer,
      };
      break;
    case ACTION_TYPES.MULTI_DRAWER_RESET:
      state = {
        ...state,
        drawers: initialState.drawers,
        refreshDrawer: initialState.refreshDrawer,
      };
      break;
    case ACTION_TYPES.SET_CAN_CLOSE_MULTI_DRAWER:
      {
        const index = action.payload.index || tempDrawers.length - 2;
        tempDrawers[index].canClose = action.payload.canClose;
        state = {
          ...state,
          drawers: tempDrawers,
        };
      }
      break;
    case ACTION_TYPES.UPDATE_MULTI_DRAWER:
      {
        const index =
          typeof action.payload.index !== "undefined"
            ? action.payload.index
            : tempDrawers.length - 2;
        tempDrawers[index] = action.payload.drawer;
        state = {
          ...state,
          drawers: tempDrawers,
        };
      }
      break;
    case ACTION_TYPES.UPDATE_MULTI_DRAWER_ACTIONS:
      {
        const index =
          typeof action.payload.index !== "undefined"
            ? action.payload.index
            : tempDrawers.length - 2;
        tempDrawers[index] &&
          (tempDrawers[index].actions = action.payload.drawerActions);
        state = {
          ...state,
          drawers: tempDrawers,
        };
      }
      break;
    case ACTION_TYPES.RESET_MULTI_DRAWER_ACTIONS:
      {
        const index =
          typeof action.payload.index !== "undefined"
            ? action.payload.index
            : tempDrawers.length - 2;
        tempDrawers[index] && (tempDrawers[index].actions = []);
        state = {
          ...state,
          drawers: tempDrawers,
        };
      }
      break;
    case ACTION_TYPES.UPDATE_MULTI_DRAWER_EXTRA_ACTIONS:
      {
        const index =
          typeof action.payload.index !== "undefined"
            ? action.payload.index
            : tempDrawers.length - 2;
        tempDrawers[index] &&
          (tempDrawers[index].extraActions = action.payload.drawerActions);
        state = {
          ...state,
          drawers: tempDrawers,
        };
      }
      break;
  }
  return state;
};

export default multiDrawerReducer;
