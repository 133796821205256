import ACTION_TYPES from "../actions/commonActionTypes";

const initialState = {
  data: {},
};

const literalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOAD_LITERALS:
      state = {
        ...state,
        data: { ...state.data, [action.payload.key]: action.payload.literals },
      };
      break;
  }
  return state;
};

export default literalReducer;
