import ACTION_TYPES from "../actions/commonActionTypes";
import { SHOW_ERROR_IN } from "../../constants/configuration";

const commonPageData = {
  // below is the common reducer data for all pages
  appliedFilter: {},
  isDetailViewFetching: false,
  isDetailViewEditable: false,
  isFirstFetch: false,
  isFetching: false,
  isInitialFetch: true,
  list: new Map(),
  selectedListItem: null,
  showButtonLoader: false,
  shouldLoadMore: true,
  totalCount: 0,
  email:""
};

const initialState = {
  ...{
    errorIn: SHOW_ERROR_IN.NONE,
    imageSrc: "",
    replaceWithElement: [],
    route: "",
    showError: false,
    lKey: "",
  },
  ...commonPageData,
};

const twoColumnReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SCREEN_SHOW_ERROR:
      state = {
        ...state,
        ...{
          showError: true,
          errorIn: SHOW_ERROR_IN.SCREEN,
          imageSrc: action.payload.imageSrc,
          route: action.payload.route,
          lKey: action.payload.lKey,
          replaceWithElement: action.payload.replaceWithElement,
        },
      };
      break;
    case ACTION_TYPES.DETAIL_SHOW_ERROR:
      state = {
        ...state,
        ...{
          showError: true,
          errorIn: SHOW_ERROR_IN.DETAIL,
          imageSrc: action.payload.imageSrc,
          route: action.payload.route,
          lKey: action.payload.lKey,
          replaceWithElement: action.payload.replaceWithElement,
        },
      };
      break;
    case ACTION_TYPES.RESET_ERROR:
      state = {
        ...state,
        ...{
          showError: initialState.showError,
          errorIn: initialState.errorIn,
          imageSrc: initialState.imageSrc,
          route: initialState.route,
          lKey: initialState.lKey,
          replaceWithElement: initialState.replaceWithElement,
        },
      };
      break;
    case ACTION_TYPES.RESET_COMMON_DATA:
      state = {
        ...state,
        ...commonPageData,
      };
      break;
    case ACTION_TYPES.SET_SELECTED_LIST_ITEM:
      state = {
        ...state,
        selectedListItem: action.payload,
      };
      break;
    case ACTION_TYPES.SET_SHOW_BUTTON_LOADER:
      state = {
        ...state,
        showButtonLoader: action.payload,
      };
      break;
    case ACTION_TYPES.SET_FILTER_DATA:
      state = {
        ...state,
        appliedFilter: action.payload,
      };
      break;
    case ACTION_TYPES.SET_LIST_DATA:
      state = {
        ...state,
        list: action.payload,
      };
      break;
    case ACTION_TYPES.SET_FETCHING_STATE:
      state = {
        ...state,
        isFetching: action.payload,
      };
      break;
    case ACTION_TYPES.SET_FIRST_FETCH_STATE:
      state = {
        ...state,
        isFirstFetch: action.payload,
      };
      break;
    case ACTION_TYPES.SET_DETAIL_VIEW_FETCHING_STATE:
      state = {
        ...state,
        isDetailViewFetching: action.payload,
      };
      break;
    case ACTION_TYPES.SET_DETAIL_VIEW_EDITABLE:
      state = {
        ...state,
        isDetailViewEditable: action.payload,
      };
      break;
    case ACTION_TYPES.SET_LOAD_MORE:
      state = {
        ...state,
        shouldLoadMore: action.payload,
      };
      break;
    case ACTION_TYPES.SET_TOTAL_COUNT:
      state = {
        ...state,
        totalCount: action.payload,
      };
      break;
    case ACTION_TYPES.SET_INTITAL_FETCH_STATE:
      state = {
        ...state,
        isInitialFetch: action.payload,
      };
      break;
    case ACTION_TYPES.SET_CHECKOUT_PAGE_EMAIL:
      state = {
        ...state,
        email: action.payload,
      };
    break;
  }
  return state;
};

export default twoColumnReducer;
