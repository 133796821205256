import ACTION_TYPES from "../actions/commonActionTypes";

const initialState = {
  notifications: new Map(),
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.NOTICATIONS_SET_LIST:
      state = {
        ...state,
        notifications: action.payload,
      };
      break;
  }
  return state;
};

export default notificationReducer;
